
          @import "~@sas-te/alfabeto-tokens/src/scss/functions";
          @import "~@sas-te/alfabeto-tokens/src/scss/variables";
          @import "~@sas-te/alfabeto-tokens/src/scss/mixins";
        







































































































































































































































.sas-input {
  display: inline-block;

  &__wrapper {
    position: relative;
  }

  &__label {
    display: inline-block;
    margin-bottom: $size-xs;

    + .sas-input__example {
      margin-top: -$size-xxs;
    }
  }

  &__example {
    margin-bottom: $size-xs;
    font-size: $font-size-s;
    font-weight: $font-weight-medium;
    line-height: $line-height-reset;
    color: $color-ink-lightest;
  }

  &__label__info-container {
    position: relative;
    top: 2px;
    display: inline-block;

    .feather svg {
      width: 14px;
      height: 14px;
    }
  }

  &__field {
    position: relative;
    display: block;
    width: 100%;
    padding: $input-v-padding-m $input-h-padding-m;
    font-size: $font-size-m;
    line-height: $line-height-input;
    color: $color-ink;
    background-color: $input-background;
    border: $input-border-size solid $input-border-color;
    border-radius: $border-radius-m;
    box-shadow: $input-inset-shadow;
    appearance: none;
    @include transition($speed-fast);

    &::placeholder {
      font-family: $font-family-primary;
      color: $color-ink-lighter;
    }

    &:disabled {
      @include disabled-style();
    }

    &:focus {
      @include input-focus-outline();
    }

    &.--error {
      @include input-focus-outline($color-danger-light);

      &:focus {
        @include input-focus-outline($color-danger-dark);
      }
    }

    &.--success {
      @include input-focus-outline($color-success-light);

      &:focus {
        @include input-focus-outline($color-success-dark);
      }
    }

    &.--warning {
      @include input-focus-outline($color-warning);

      &:focus {
        @include input-focus-outline($color-warning-dark);
      }
    }

    &.--large {
      padding: $input-v-padding-l $input-h-padding-l;
      border-radius: $border-radius-l;

      &,
      &::placeholder {
        font-size: $font-size-m;
      }
    }

    &.--small {
      padding: $input-v-padding-s $input-h-padding-s;
      border-radius: $border-radius-s;

      &,
      &::placeholder {
        font-size: $font-size-xs;
      }
    }

    &.--icon-left {
      &.--large {
        padding-left: $icon-size-l;
      }

      &.--medium {
        padding-left: $icon-size-m;
      }

      &.--small {
        padding-left: $icon-size-s;
      }
    }

    &.--icon-right {
      &.--large {
        padding-right: $icon-size-l;
      }

      &.--medium {
        padding-right: $icon-size-m;
      }

      &.--small {
        padding-right: $icon-size-s;
      }
    }
  }

  &__icon {
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    pointer-events: none;

    &.--large {
      width: $icon-size-l;
    }

    &.--medium {
      width: $icon-size-m;
    }

    &.--small {
      width: $icon-size-s;
    }

    &.--left {
      left: $input-border-size;
    }

    &.--right {
      right: $input-border-size;
    }
  }

  &__extras {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    @include flex-center();

    &.--large {
      height: $input-size-l;
      padding: 0 8px;
    }

    &.--medium {
      height: $input-size-m;
      padding: 0 4px;
    }

    &.--small {
      height: $input-size-s;
      padding: 0 2px;
    }
  }
}

.tooltip.popover {
  .popover-inner.sas-input__label__info-popover {
    max-width: 205px;
    padding: 12px;
    font-size: 13px;
    color: $color-white;
    letter-spacing: .02em;
    background-color: $color-primary-darkest;
  }

  + .popover-arrow {
    border-color: $color-primary-darkest;
  }
}
