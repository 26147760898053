
          @import "~@sas-te/alfabeto-tokens/src/scss/functions";
          @import "~@sas-te/alfabeto-tokens/src/scss/variables";
          @import "~@sas-te/alfabeto-tokens/src/scss/mixins";
        


























































































































































































.sas-button {
  position: relative;
  cursor: pointer;
  user-select: none;
  border: none;
  appearance: none;
  @include flex-center($inline: true);
  @include transition($speed-fast);

  &:focus {
    outline: 0;
  }

  &:disabled {
    @include disabled-style();
  }

  // --------------------------------------------------------------
  // Loading State
  // --------------------------------------------------------------
  &.--loading {
    justify-content: center;
    cursor: progress;
    opacity: 1;

    .sas-button__icon {
      opacity: 0;
      transform: scale(0);
    }

    .sas-button__text {
      opacity: 0;
      transform: scale(0);
    }
  }

  &__spinner {
    position: absolute;
  }

  &__text {
    font-weight: $font-weight-semi-bold;
    line-height: $line-height-heading;
    @include transition($speed-fast);
  }

  // --------------------------------------------------------------
  // Sizes
  // --------------------------------------------------------------
  &.--large {
    height: $button-size-l;
    font-size: $font-size-m;
    border-radius: $border-radius-l;
    @include space-inset(0 $button-padding-l);
  }

  &.--medium {
    height: $button-size-m;
    font-size: $font-size-s;
    border-radius: $border-radius-m;
    @include space-inset(0 $button-padding-m);
  }

  &.--small {
    height: $button-size-s;
    font-size: $font-size-xs;
    border-radius: $border-radius-s;
    @include space-inset(0 $button-padding-s);
  }

  // --------------------------------------------------------------
  // Status
  // --------------------------------------------------------------
  &.--error {
    box-shadow: 0 0 0 2px $color-danger;
  }

  // --------------------------------------------------------------
  // Variations
  // --------------------------------------------------------------
  &.--primary {
    color: $color-white;
    @include button-style($color-primary);
  }

  &.--secondary {
    color: $color-ink-light;
    border: 1px solid rgba($color-ink, .25);
    @include button-style($color-white);
  }

  &.--danger {
    color: $color-white;
    @include button-style($color-danger);
  }

  &.--tertiary {
    color: $color-ink-light;
    background: transparent;
    box-shadow: none;
    @include space-inset(0 $size-xs);

    &:hover {
      background: rgba($color-ink, .15);
    }

    &:active {
      background: rgba($color-ink, .25);
    }

    &:focus {
      @include focus-style();
    }
  }

  &.--link {
    color: $color-primary;
    background-color: transparent;

    &:hover {
      text-decoration: underline;
    }
  }

  // --------------------------------------------------------------
  // Icon
  // --------------------------------------------------------------
  &__icon {
    display: flex;
    @include transition($speed-fast);

    // Icon Sizes
    &.--large {
      @include element-icon-size($button-icon-size-l);
    }

    &.--medium {
      stroke-width: 2.3;
      @include element-icon-size($button-icon-size-m);
    }

    &.--small {
      stroke-width: 2.5;
      @include element-icon-size($button-icon-size-s);
    }

    // Icon Right
    &.--right {
      order: 1;
      margin-left: $size-xs;
    }

    // Icon Left
    &.--left {
      margin-right: $size-xs;
    }
  }

  // --------------------------------------------------------------
  // Icon Only
  // --------------------------------------------------------------
  &.--icon-only {
    justify-content: center;
    @include space-inset(0 !important);

    &.--large {
      width: $button-size-l;
    }

    &.--medium {
      width: $button-size-m;
    }

    &.--small {
      width: $button-size-s;
    }
  }
}
