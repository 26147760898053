
          @import "~@sas-te/alfabeto-tokens/src/scss/functions";
          @import "~@sas-te/alfabeto-tokens/src/scss/variables";
          @import "~@sas-te/alfabeto-tokens/src/scss/mixins";
        

































































.sas-skeleton-loader {
  position: relative;
  display: block;
  overflow: hidden;
  background: $skeleton-color;

  &::before {
    background:
      linear-gradient(
        140deg,
        transparent 25%,
        $skeleton-highlight 50%,
        transparent 75%
      );
    animation: {
      name: pulse;
      duration: 1600ms;
      timing-function: ease-in-out;
      iteration-count: infinite;
      fill-mode: forwards;
    }
    @include pseudo();
    @include cover(absolute);
  }
}

@keyframes pulse {
  0% {
    transform: translate3d(-80%, 0, 0);
  }

  100%{
    transform: translate3d(120%, 0, 0);
  }
}
