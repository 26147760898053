
          @import "~@sas-te/alfabeto-tokens/src/scss/functions";
          @import "~@sas-te/alfabeto-tokens/src/scss/variables";
          @import "~@sas-te/alfabeto-tokens/src/scss/mixins";
        






















.sas-dropdown-menu__item {
  justify-content: flex-start;
  height: auto !important;
  text-align: left;
  border-radius: 0 !important;
  @include space-inset(12px $size-s !important);

  &:not(:first-child) {
    border-top: 1px solid rgba($color-ink, .15);
  }
}
