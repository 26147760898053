
          @import "~@sas-te/alfabeto-tokens/src/scss/functions";
          @import "~@sas-te/alfabeto-tokens/src/scss/variables";
          @import "~@sas-te/alfabeto-tokens/src/scss/mixins";
        








































































































.progress-bar {
  position: relative;

  &__background,
  &__foreground {
    transition: all 800ms cubic-bezier(.86, 0, .07, 1);
  }
}
