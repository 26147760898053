
          @import "~@sas-te/alfabeto-tokens/src/scss/functions";
          @import "~@sas-te/alfabeto-tokens/src/scss/variables";
          @import "~@sas-te/alfabeto-tokens/src/scss/mixins";
        
























































































.sas-badge {
  position: relative;
  border-radius: $badge-border-radius;
  @include flex-center($inline: true);

  &__text {
    font-weight: $font-weight-medium;
    text-align: center;
    mix-blend-mode: multiply;
  }

  // --------------------------------------------------------------
  // Icon
  // --------------------------------------------------------------
  &__icon {
    mix-blend-mode: multiply;
    opacity: .75;

    // Icon Right
    &--right {
      order: 1;
      margin-left: $size-xs;
    }

    // Icon Keft
    &--left {
      margin-right: $size-xs;
    }
  }

  &--icon-only {
    @include space-inset(0 !important);
  }

  // --------------------------------------------------------------
  // Sizes
  // --------------------------------------------------------------
  &--large {
    height: $badge-size-l;
    @include space-inset(0 $badge-padding-l);

    span {
      font-size: $badge-font-size-l;
    }

    .sas-badge__icon {
      @include element-icon-size($badge-icon-size-l);
    }

    &.sas-badge--icon-only {
      width: $badge-size-l;
    }
  }

  &--medium {
    height: $badge-size-m;
    @include space-inset(0 $badge-padding-m);

    span {
      font-size: $badge-font-size-m;
    }

    .sas-badge__icon {
      stroke-width: 2.3;
      @include element-icon-size($badge-icon-size-m);
    }

    &.sas-badge--icon-only {
      width: $badge-size-m;
    }
  }

  &--small {
    height: $badge-size-s;
    @include space-inset(0 $badge-padding-s);

    span {
      font-size: $badge-font-size-s;
    }

    .sas-badge__icon {
      stroke-width: 2.5;
      @include element-icon-size($badge-icon-size-s);
    }

    &.sas-badge--icon-only {
      width: $badge-size-s;
    }
  }

  // --------------------------------------------------------------
  // Variations
  // --------------------------------------------------------------
  &--primary {
    color: $color-primary-dark;
    background: $color-primary-lightest;
  }

  &--neutral {
    color: $color-ink-light;
    background: tint($color-ink-lightest, 25%);
  }

  &--success {
    color: $color-success-darker;
    background: $color-success-lightest;
  }

  &--warning {
    color: $color-warning-darker;
    background: $color-warning-lighter;
  }

  &--danger {
    color: $color-danger-dark;
    background: $color-danger-lightest;
  }
}
